<template>
  <div>
    <div class="video-bg">
      <video
        autoplay="autoplay"
        class="my-video"
        loop="loop"
        muted="muted"
        poster="https://monmall-1253436512.cos.accelerate.myqcloud.com/CDN/d7ce5e63b8c012e1450d675b70195f78.jpg"
        preload="auto"
        src="https://monmall-1253436512.cos.accelerate.myqcloud.com/CDN/MONMALL-BG.mp4"
      ></video>
    </div>
    <div class="select-box">
      <div class="mar-left-50 d-flex align-items-center">
        <div class="storeCenter">{{ $t('signIn.sellerCenter') }}</div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link d-flex align-items-center">
            <img
              :src="flagValue"
              alt
              height="20"
              style="border-radius: 50%;margin-right:5px;"
              width="20"
            />
            {{ siteValue }}
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in siteListArr"
              :key="index"
              :command="beforeHandleCommand(item.siteName, item.countryFlag, item.siteUrl)"
            >
              <div class="el-dropdown-width" style="text-align: left">
                <img
                  :src="item.countryFlag"
                  alt
                  height="20"
                  style="border-radius: 50%;margin-right:5px;"
                  width="20"
                />
                {{ item.siteName }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown class="mar-left-20" @command="handleCommandLanguage">
          <span class="el-dropdown-link">
            {{ $t('language.language') }}
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh_CN">
              <div class="el-dropdown-width">{{ $t('language.chinese') }}</div>
            </el-dropdown-item>
            <el-dropdown-item command="en_US">
              <div class="el-dropdown-width">{{ $t('language.english') }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="page-account">
      <div class="login-animation">
        <!--        <el-image class="shopping" :src="require('@/assets/image/login/ic_shopping@2x.png')"></el-image>-->
        <!--        <div data-x="80" data-rotate="360" class="earphone el">-->
        <!--          <el-image :src="require('@/assets/image/login/ic_earphone@2x.png')"></el-image>-->
        <!--        </div>-->
        <!--        <div data-y="80" data-rotate="-80" class="gift el">-->
        <!--          <el-image :src="require('@/assets/image/login/ic_gift@2x.png')"></el-image>-->
        <!--        </div>-->
        <!--        <div data-rotate="-60" class="shop el">-->
        <!--          <el-image :src="require('@/assets/image/login/ic_shop@2x.png')"></el-image>-->
        <!--        </div>-->
        <!--        <div data-y="80" class="shoppingbag el">-->
        <!--          <el-image :src="require('@/assets/image/login/ic_shoppingbag@2x.png')"></el-image>-->
        <!--        </div>-->
        <!--        <div data-y="-50" data-x="-30" data-rotate="-80" class="shoppingcart el">-->
        <!--          <el-image :src="require('@/assets/image/login/ic_shoppingcart@2x.png')"></el-image>-->
        <!--        </div>-->
      </div>
      <div class="w100px mr50 ml40"></div>
      <div class="d-flex mab-40 flex-direction-column">
        <h1 class="pad-20-0 color-white">{{ $t('signIn.registeredSellers') }}</h1>
        <div class="pad-bottom-20 color-white width-385">{{ $t('signIn.switchLocalMerchants') }}</div>
        <div class="index_from page-account-container">
          <div class="labelPic">
            <div v-if="!isCode" style="display: flex; align-items: center">
              <div
                style="background: rgba(0,0,0,0.4);color: white;padding: 0 8px;border-radius: 8px;font-size: 12px"
              >{{ $t('signIn.scanDownload') }}</div>
              <div
                style="margin-right: -15px; width: 0; height: 0; border-top: 6px solid transparent;border-left: 8px solid rgba(0,0,0,0.4;    border-bottom: 6px solid transparent;"
              ></div>
              <img
                style="cursor: pointer"
                @click="isCode = true"
                src="@/assets/image/login/qr-code.webp"
                height="50"
                width="50"
                alt
              />
            </div>
            <img
              v-else
              style="cursor: pointer"
              @click="isCode = false"
              src="@/assets/image/login/qr-computer.webp"
              height="50"
              width="50"
              alt
            />
          </div>
          <div class="page-account-top">
            <div class="page-account-top-logo">
              <img :src="require('@/assets/image/login/logo.svg')" alt height="42" />
            </div>
          </div>
          <div v-show="isCode" style="display: flex; items-align: center;justify-content:center">
            <div style="text-align: center;;margin-top: 20px">
              <div
                style="border: 1px solid black; padding; 4px;width: 150px;height: 150px;border-radius: 12px;display: inline-block;"
              >
                <img
                  style="width: 100% ;height: 100%;border-radius: 12px;display: inline-bolk;"
                  src="@/assets/image/login/merAppCode.png"
                  alt
                />
              </div>
              <div
                style="display: flex; items-align: center;justify-content:center;margin-top: 20px"
              >
                <img
                  style="width: 20px; height: 20px;margin-right: 10px"
                  src="@/assets/image/login/ic_scan.png"
                  alt
                />
                <div>Scan To Download App</div>
              </div>
              <div style="display: flex; align-items:center;margin-top: 30px">
                <a href="https://monmall.my/seller-monmall.apk" target="downloadFile">
                  <div
                    style="display: flex; align-items:center;border-radius: 6px;border: 1px solid #BDC4D4;padding: 8px;cursor: pointer;"
                  >
                    <img
                      width="30"
                      style="margin-right: 5px"
                      src="@/assets/image/login/android.png"
                      alt
                    />
                    <div style="text-align: left;">
                      Download
                      <br />Android APK
                    </div>
                  </div>
                </a>

                <div style="width: 10px"></div>
                <a href="https://apps.apple.com/cn/app/monmall/id6443844306" target="_black">
                  <div
                    style="display: flex; align-items:center;background: #404B5B;border-radius: 6px;color: #FFFFFF;padding: 8px;cursor: pointer;"
                  >
                    <img
                      width="24"
                      style="margin-right: 5px"
                      src="@/assets/image/login/ios.png"
                      alt
                    />
                    <div style="text-align: left;">
                      Iphone
                      <br />On App Store
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <!-- <div>
              <img
                v-if="qrCode"
                :src="qrCode"
                :style="isExpired === true ? 'opacity:0.05' : ''"
                alt
                height="300"
                width="300"
                @click="refreshQrCode"
              />
            </div>
            <div
              v-show="isExpired"
              style="margin-top: -160px; margin-bottom: 180px"
              @click="refreshQrCode"
            >Refresh the QR code</div>-->
          </div>
          <div v-show="!isCode">
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginRules"
              autocomplete="on"
              class="login-form"
              label-position="left"
              @keyup.enter="handleLogin"
            >
              <el-form-item class="login-input" prop="phone">
                <el-input
                  ref="account"
                  v-model="loginForm.phone"
                  :placeholder="$t('signIn.mobilePhoneNumber')"
                  autocomplete="on"
                  name="username"
                  prefix-icon="el-icon-user"
                  tabindex="1"
                  type="text"
                >
                  <el-select
                    slot="prepend"
                    v-model="loginForm.area"
                    :placeholder="$t('base.pleaseSelectA')"
                    clear="input-select"
                    style="width:70px;"
                  >
                    <el-option
                      v-for="(item, index) in areaCodeArray"
                      :key="index"
                      :label="item.areaCode"
                      :value="item.areaCode"
                    ></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item class="login-input" prop="pwd">
                <el-input
                  :key="passwordType"
                  ref="pwd"
                  v-model="loginForm.pwd"
                  :placeholder="$t('signIn.password')"
                  :type="passwordType"
                  auto-complete="on"
                  name="password"
                  prefix-icon="el-icon-lock"
                  tabindex="2"
                />
                <span class="show-pwd" @click="showPwd">
                  <img
                    v-if="passwordType === 'password'"
                    alt
                    src="@/assets/image/home/openEye.png"
                    width="20px"
                  />
                  <img v-else alt src="@/assets/image/home/closeEye.png" width="20px" />
                </span>
              </el-form-item>
              <el-form-item v-loading="captchaLoad" class="captcha" prop="code">
                <div class="captcha">
                  <el-input
                    ref="username"
                    v-model="loginForm.code"
                    :placeholder="$t('signIn.verificationCode')"
                    autocomplete="on"
                    name="username"
                    prefix-icon="el-icon-message"
                    style="width: 190px;"
                    tabindex="3"
                    type="text"
                  />
                  <div class="imgs" @click="getCaptcha()">
                    <img ref="captchaImg" :src="captchatImg" alt @load="captchaLoadOver" />
                  </div>
                </div>
              </el-form-item>
              <el-button
                :loading="loading"
                class="w-100"
                type="primary"
                @click.native.prevent="handleLogin"
              >{{ $t('signIn.login') }}</el-button>
              <el-form-item>
                <el-button
                  style="width:100%;margin-top: 15px;background: #FCB724;border: 1px solid #FCB724;color: white"
                  @click="toRegisterBtn()"
                >{{ $t('signIn.createMerchantAccount') }}</el-button>
                <div class="forgot_password cursor-pointer">
                  <a @click="forgetPassword()">{{ $t('signIn.forgotPassword') }}</a>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import QRCode from 'qrcodejs2'
import Cookies from 'js-cookie'
import { graphicCode, merchantLogin, siteList } from '@/api/base'

export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      // console.log("value1", value)
      if (!value) {
        callback(new Error(this.$t('signIn.pleaseEnterYourAccount')))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      // console.log("value2", value)
      if (!value) {
        callback(new Error(this.$t('signIn.pleaseEnterYourPassword')))
      } else {
        if (value.length < 8) {
          callback(new Error(this.$t('signIn.passwordCannotBeLessThanEight')))
        } else {
          callback()
        }
      }
    }
    return {
      flagValue: 'https://monmall-1253436512.cos.accelerate.myqcloud.com/images/2022-02/113d90d6ae2b4270893a62c440b0c3ef.jpg',
      siteValue: '菲律宾',
      siteListArr: [], // 所有的站点
      //区号
      area: '86',
      // 下载二维码
      isCode: false,
      captchaLoad: false,
      //登录、找回密码
      isLogin: true,
      captchatImg: '',
      loginForm: {
        // 区号
        area: '86',
        //手机号码
        phone: '',
        // 密码
        pwd: '',
        uuid: '',
        //验证码
        code: '',
      },
      areaCodeArray: [{ areaCode: '86' }, { areaCode: '63' }, { areaCode: '60' }, { areaCode: '66' }, { areaCode: '65' }],
      loginRules: {
        phone: [
          {
            required: true,
            trigger: 'blur',
            // message: this.$t('signIn.pleaseEnterYourAccount'),
            validator: validateUsername,
          },
        ],
        pwd: [{ required: true, trigger: 'blur', validator: validatePassword }],
        code: [{ required: true, message: this.$t('signIn.pleaseEnterVerificationCode'), trigger: 'blur' }],
      },
      passwordType: 'password',
      loading: false,
    }
  },
  mounted() {
    this.getSiteList()
    this.getCaptcha()
    sessionStorage.getItem('loginInfo') ? (this.loginForm = JSON.parse(sessionStorage.getItem('loginInfo'))) : ''
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false)
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false)
  },
  methods: {
    goBack() {
      this.$router.replace({ path: '/' })
      //replace替换原路由，作用是避免回退死循环
    },
    // 获取站点
    getSiteList() {
      siteList().then((res) => {
        this.siteListArr = res.data
        if (this.siteListArr.length !== 0) {
          this.siteValue = this.siteListArr[0].siteName
          this.flagValue = this.siteListArr[0].countryFlag
        }
      })
    },
    // 获取图像验证码
    getCaptcha() {
      this.captchaLoad = false
      graphicCode()
        .then(({ data }) => {
          this.captchatImg = data.img
          this.loginForm.uuid = data.uuid
          // this.captchaLoad = false
        })
        .catch(({ message }) => {
          this.$message.error(message)
          // this.captchaLoad = false
        })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
    },
    captchaLoadOver(val) {
      if (val && this.$refs.captchaImg) this.captchaLoad = false
    },
    //选择站点
    handleCommand(command) {
      // this.siteValue = command.siteName
      // this.flagValue = command.countryFlag
      // window.location.href = command.siteUrl
      window.open(command.siteUrl, '_blank')
    },
    beforeHandleCommand(siteName, countryFlag, siteUrl) {
      return {
        countryFlag: countryFlag,
        siteName: siteName,
        siteUrl: siteUrl,
      }
    },
    // 切换语言
    handleCommandLanguage(command) {
      if (command === 'zh_CN') {
        Cookies.set('language', 'zh_CN')
        this.$i18n.locale = 'zh_CN'
      } else {
        Cookies.set('language', 'en_US')
        this.$i18n.locale = 'en_US'
      }
      this.$router.go(0)
    },
    // 登入
    handleLogin() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          merchantLogin(this.loginForm)
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data.userInfo, 'res.data.userInfo')
                this.$message.success(res.message)
                this.$store.commit('setToken', res.data.token)
                this.$store.commit('setInfo', res.data.userInfo)
                Cookies.set('loginForm', JSON.stringify(this.loginForm))
                Cookies.set('area', this.loginForm.area)
                this.$router.push({ path: '/signIn/settled/applicationRecord', query: { area: this.loginForm.area } })
                this.loading = false
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.getCaptcha()
              this.loading = false
            })
        }
      })
    },
    toRegisterBtn() {
      this.$router.push({ path: '/signIn/register' })
    },
    forgetPassword() {
      this.$router.push({ path: '/forgetPassword' })
    },
  },
}
</script>
<style>
a {
  text-decoration: none;
  color: black;
}
.index_bg {
  background: rgba(0, 0, 0, 0) !important;
  z-index: -3 !important;
}
</style>

<style scoped>
.select-box {
  z-index: 5000;
  position: absolute;
  top: 50px;
  right: 100px;
  display: flex;
}

.storeCenter {
  /*width: 120px;*/
  height: 28px;
  padding: 0 10px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  margin-right: 20px;
  background: url('../../../assets/image/login/bg_img.webp');
  background-size: 100% 100%;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 14px;
}

.login-bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 76px);
  /* background-image: url('../../../assets/image/login/bg_img@2x.png'); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: 100% 100%;
  background-clip: content-box;
}

.login-animation {
  width: 663px;
  height: 567px;
  position: relative;
  user-select: none;
  -webkit-user-drag: none;
}

.el-image img {
  user-select: none;
  -webkit-user-drag: none;
}

.shopping {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
}

.earphone {
  position: absolute;
  top: 40%;
  right: 10%;
}

.gift {
  position: absolute;
  top: 35%;
  left: 0;
}

.shop {
  position: absolute;
  bottom: 20%;
  right: 0;
}

.shoppingbag {
  position: absolute;
  top: 0;
  right: 20%;
}

.shoppingcart {
  position: absolute;
  bottom: 0;
  left: 10%;
}

.demo-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 290px;
  height: 100%;
}

.line {
  width: 100%;
  padding: 1px 0;
}

.small {
  width: 18px;
  height: 18px;
}

.circle {
  pointer-events: none;
  position: relative;
  width: 28px;
  height: 28px;
  margin: 1px;
  background-color: currentColor;
  font-size: 14px;
  border-radius: 50%;
}

.shadow {
  position: absolute;
  opacity: 0.2;
}

.page-account {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
}

.ivu-poptip,
.ivu-poptip-rel {
  display: block;
}

.labelPic {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 34px;
  border-radius: 17px 0px 0px 17px;
  line-height: 34px;
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

/*@media (min-width: 768px) {*/
/*  .page-account {*/
/*    background-color: rgba(0, 0, 0, 0.4);*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/*    background-size: cover;*/
/*  }*/

/*  .page-account-container {*/
/*    padding: 32px 0 24px 0;*/
/*    position: relative;*/
/*  }*/
/*}*/

.page-account {
  background-color: rgba(0, 0, 0, 0.4);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.video-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.my-video {
  min-height: 100%;
  min-width: 100%;
}

/*@media (max-width: 800px) {*/
/*  .login_text,*/
/*  .login-animation,*/
/*  .w100px {*/
/*    display: none;*/
/*  }*/

/*  .login-img {*/
/*    display: none;*/
/*  }*/
/*}*/

.login-img {
  margin-bottom: -300px;
}

.ml40 {
  margin-left: 40px !important;
}

.mr50 {
  margin-right: 50px;
}

.w100px {
  width: 100px !important;
}

.page-account {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-account .code {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-account .code .pictrue {
  height: 40px;
}

.swiperPross {
  border-radius: 6px 0px 0px 6px;
  overflow: hidden;
}

.swiperPross,
.swiperPic,
.swiperPic img {
  width: 510px;
  height: 100%;
}

.swiperPic img {
  width: 100%;
  height: 100%;
}

.container {
  padding: 0 !important;
  /*overflow: hidden;*/
  border-radius: 6px;
  z-index: 1;
  display: flex;
}

.containerBig {
  width: auto !important;
  background: #f7f7f7 !important;
}

.index_from {
  padding: 0 50px 32px 50px;
  box-sizing: border-box;
  background: white;
}

.page-account-top {
  padding: 20px 0 !important;
  box-sizing: border-box !important;
  display: flex;
}

.page-account-container {
  border-radius: 16px;
  position: relative;
}

.btn {
  background: linear-gradient(90deg, rgba(25, 180, 241, 1) 0%, rgba(14, 115, 232, 1) 100%) !important;
}
</style>

<style scoped>
.captcha {
  display: flex;
  align-items: flex-start;
}

.imgs img {
  height: 36px;
  margin-bottom: -14px;
}

.login-form {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.tips {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}

.svg-container {
  padding: 6px 5px 6px 15px;
  color: #889aa4;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}

.show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  user-select: none;
}

.svg-icon {
  vertical-align: 0.3em;
}

.thirdparty-button {
  position: absolute;
  right: 0;
  bottom: 6px;
}

.login_text {
  /* background: url('../../assets/image/bg_img.png'); */
  background-size: 100% 100%;
  width: 636px;
  height: 636px;
  text-align: left;
  margin-bottom: -200px;
  z-index: 999;
}

.text_1 {
  font-size: 45px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}

.text_2 {
  font-size: 69px;
  font-family: PingFangSC;
  font-weight: 500;
  color: #ffffff;
}

.forgot_password {
  margin-top: -5px;
  margin-bottom: -20px;
  width: 100%;
  text-align: right;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #006cbf;
}

.login {
  margin-top: 5px;
  margin-bottom: -20px;
  width: 100%;
  text-align: right;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #006cbf;
}

.settled_sms {
  margin-top: 15px;
  display: flex;
}

.settled_send {
  width: 100px;
  padding: 5px;
  height: 34px;
  background: #fe3e46;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.height {
  margin-bottom: 15px;
}

.submit {
  margin-top: 15px;
  /*width: 497px;*/
  height: 50px;
  background: linear-gradient(270deg, #fe3e46, #fe8e43);
  border-radius: 8px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
}

.qrcode {
  display: inline-block;
}

.qrcode img {
  width: 132px;
  height: 132px;
  background-color: #fff;
  padding: 6px;
  box-sizing: border-box;
}

/*设置下拉框统一宽度*/
.el-dropdown-width {
  min-width: 120px;
  text-align: center;
}
</style>
